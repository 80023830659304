<template>
  <div>
    <h2 class="uppercase pl-4 md:pl-28 pt-4 text-gray-600">
      Sie sind hier / <a style="color: #f27405" href="/home">Beroma Gmbh</a> /
      Markisen / Vollkassettenmarkise / Fino <br />
    </h2>
    <article class="container mx-auto pl-2 pt-2 lg:p-32">
      <h2 class="text-4xl md:text-6xl pb-10" style="color: #f27405">
        Die Fino
      </h2>
      <div class="grid grid-cols-2">
        <div class="col-span-full md:col-span-1">
          <productslide v-bind:slides="slides"></productslide>
        </div>
        <div class="col-span-full md:col-span-1">
          <h3
            class="text-2xl pl-5 py-5 md:pl-10 md:pb-3"
            style="color: #033859"
          >
            Elegant, verführerisch und zeitlos schön
          </h3>
          <p class="px-5 w-full md:pl-10 md:pb-3">
            Die Kassettenmarkise FINO ist heute unser beliebtestes Modell und
            der LEINER-Topseller. Die FINO überzeugt durch ihre kleine Bauweise
            und fügt sich problemlos in jede Balkon- und Terrassenoptik ein. Das
            Design der FINO ist elegant und zeitlos – auch die Seitenlager sind
            nicht nur formschön, sondern besonders stabil und belastbar. Das
            extrem belastbare Armsystem sorgt mit seiner speziellen Geometrie
            für ein straffes Markisentuch<br /><br />
            Besonders stark belastete Markisenbauteile wie beispielsweise die
            Seitenlager werden von uns aus gehärtetem Aluminium gefertigt. Unser
            spezielles Fertigungsverfahren macht die Markisenbauteile nicht nur
            widerstandsfähiger, sondern erhöht dadurch auch die Sicherheit und
            Langlebigkeit unserer Markisen. Weitere Informationen zur
            LEINER-Qualität finden Sie hier.<br /><br />
          </p>
        </div>
      </div>
      <div class="p-4">
        <h3 class="text-4xl pt-10 pb-3" style="color: #033859">Eckdaten</h3>
        <tabs :mode="mode">
          <tab title="Allgemein">
            <p class="text-lg">
              <span class="font-bold">Details</span> <br />
              Die FINO besticht durch ihr elegantes Design und die kompakte
              Bauweise. Formschöne Seitenlager aus gehärtetem Aluminium zeigen
              Stärke und Stabilität. Hierdurch ist es möglich, die FINO auch in
              sehr großen Maßen zu fertigen.<br /><br />
              Sie erhalten die FINO in der Standardfarbe weiß (RAL 9010);
              alternativ in einer der 18 LEINER-LOUNGE-Feinstrukturfarben. Gegen
              Aufpreis realisieren wir außerdem jede gewünschte RAL-Wunschfarbe
              – lassen Sie sich von Uns beraten! <br /><br />
              Unaufdringlich und zeitlos schön fügt sich die FINO in das
              Terrassen- und Balko-nambiente ein. Egal, von welcher Seite man
              die kleine Kassette betrachtet, sie ist immer einen Blick wert.
            </p>
          </tab>
          <tab title="Maßen">
            <div class="text-lg">
              <span class="font-bold">Breite:</span> <br />
              max. 500 cm - 650 cm <br />
              <span class="font-bold">Ausfall/Tiefe:</span> <br />
              max. 250 cm - 350 cm <br />
              <span class="font-bold">Winkelung:</span> <br />
              0° - 40° <br />
              <span class="font-bold">Ausstattung:</span> <br />
              LED <br />
              Safety Motion <br />
              WPS <br />
            </div>
          </tab>
        </tabs>
      </div>
    </article>
  </div>
</template>
<script>
import Tab from "../components/Tab";
import Tabs from "../components/Tabs";
import productslide from "../components/product-slide";

export default {
  components: {
    Tab,
    Tabs,
    productslide,
  },
  data() {
    return {
      slides: [
        {
          image: require("@/assets/img/leiner/fino/fino-header.webp"),
          alt: "leiner fino vollkassettenmarkise",
        },
        {
          image: require("@/assets/img/leiner/fino/fino-kl.webp"),
          alt: "leiner fino hausansicht",
        },
        {
          image: require("@/assets/img/leiner/fino/fino-header04.webp"),
          alt: "leiner fino offen untere ansicht",
        },
        {
          image: require("@/assets/img/leiner/fino/fino.webp"),
          alt: "leiner fino offen weiss",
        },
      ],
    };
  },
};
</script>
